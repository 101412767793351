import { Link, redirect, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { useRef } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { Alert, Box, Container, Divider, Paper, Snackbar } from "@mui/material";
import styled from "styled-components";

export const TBox = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #2c2c2c;
	padding: 20px;
	border-radius: 15px;
`;

export const Login = () => {
	const [cookies, setCookie, delCookie] = useCookies();
	const nav = useNavigate();

	const [error, setError] = useState<string | undefined>();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const u_data = {
		username: useRef<HTMLInputElement>(),
		password: useRef<HTMLInputElement>(),
	};

	const ClickLogin = () => {
		axios
			.post("https://vibes.mrfreex.net:8443/login", {
				username,
				password,
			})
			.then((result) => {
				if (result.status === 200) {
					setCookie("token", result.data.auth);

					axios
						.post("https://vibes.mrfreex.net:8443/get-account-type", {
							token: result.data.auth,
						})
						.then((answer) => {
							switch (answer.data) {
								case "manager":
									nav("/panel/events");
									break;
								case "code_check":
									nav("/app-info");
									break;
							}
						});
				}
			})
			.catch((reason) => {
				alert(reason);
				setError("Credenziali non valide");
			});
	};

	return (
		<>
			<TBox margin="auto" maxWidth={500}>
				<Stack spacing={1}>
					<Input value={username} onChange={(ev) => setUsername(ev.target.value)} type="text" placeholder="Username"></Input>
					<Input value={password} onChange={(ev) => setPassword(ev.target.value)} type="password" placeholder="Password"></Input>
					<div></div>
					<div></div>
					<Button variant="contained" onClick={ClickLogin}>
						Login
					</Button>
					<Button variant="outlined" href="signup">
						Register
					</Button>
				</Stack>
			</TBox>
			<Snackbar
				open={error !== undefined}
				autoHideDuration={6000}
				onClose={() => {
					setError(undefined);
				}}
			>
				<Alert severity="error" sx={{ width: "100%" }}>
					{error}
				</Alert>
			</Snackbar>
		</>
	);
};
