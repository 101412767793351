import { createContext } from "react";

interface IAppContext {
    token? : string;
}

export type {
    IAppContext
};

export const AppContext = createContext<[IAppContext, (value : IAppContext) => any]>([
    {},
    (value : IAppContext) => {} 
])
