import { Link, useNavigate } from "react-router-dom";
import { useRef, useContext, useState } from "react";
import axios from "axios";

import { AppContext } from "../Context";
import { TBox } from "./Login";
import { Alert, Button, Input, Snackbar, Stack } from "@mui/material";

export const Signup = () => {
	const nav = useNavigate();

	const inl = useRef<HTMLInputElement>();

	const [context, setContext] = useContext(AppContext);

	const [error, setError] = useState<string | undefined>();

	const [token, setToken] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const Submit = () => {
		axios
			.post("https://vibes.mrfreex.net:8443/admin-reg", {
				token: token,
			})
			.then((ans) => {
				console.log(ans.data.jwt);
				//alert(ans.data.jwt)
				axios
					.post("https://vibes.mrfreex.net:8443/admin-reg-conf", {
						jwt: ans.data.jwt,
						user_data: {
							username: username,
							password: password,
						},
					})
					.then((ans) => {
						console.log(ans.status, ans.data);
						if (ans.status === 200) {
							setContext({
								...context,
								token: ans.data.auth,
							});

							axios
								.post("https://vibes.mrfreex.net:8443/get-account-type", {
									token: ans.data.auth,
								})
								.then((answer) => {
									switch (answer.data) {
										case "manager":
											nav("/panel/events");
											break;
										case "code_check":
											nav("/app-info");
											break;
									}
								});
						}
					})
					.catch(() => {
						setError("Token non valido");
					});
			})
			.catch(() => {
				setError("Token non valido");
			});
	};

	return (
		<>
			<TBox margin="auto" maxWidth={500}>
				<Stack spacing={1}>
					<Input value={token} onChange={(ev) => setToken(ev.target.value)} type="text" placeholder="Token"></Input>
					<Input value={username} onChange={(ev) => setUsername(ev.target.value)} type="text" placeholder="Username"></Input>
					<Input value={password} onChange={(ev) => setPassword(ev.target.value)} type="password" placeholder="Password"></Input>
					<div></div>
					<div></div>
					<Button variant="contained" onClick={Submit}>
						Confirm
					</Button>
				</Stack>
			</TBox>
			<Snackbar
				open={error !== undefined}
				autoHideDuration={6000}
				onClose={() => {
					setError(undefined);
				}}
			>
				<Alert severity="error" sx={{ width: "100%" }}>
					{error}
				</Alert>
			</Snackbar>
		</>
	);
};

/*

export const Login = () => {

  const [cookies, setCookie, delCookie] = useCookies();
  const nav = useNavigate();

  const [error, setError] = useState<string | undefined>();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const u_data = {
    username : useRef<HTMLInputElement>(),
    password : useRef<HTMLInputElement>()
  }

  const ClickLogin = () => {
    axios.post("https://vibes.mrfreex.net:8443/login", {
      username, password
    }).then((result) => {
      if (result.status === 200) {

        setCookie("token", result.data.auth);

        axios.post("https://vibes.mrfreex.net:8443/get-account-type", {
          token : result.data.auth
        }).then(answer => {
          switch (answer.data){
            case "manager": nav("/panel/events"); break;
            case "code_check": nav("/app-info"); break;
          }
        }) 
      }
    }).catch((reason) => {
      setError("Credenziali non valide")
    })
  }



  
}

*/
