import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";

export const Scanned = () => {
	const [query] = useSearchParams();
	const [cookies] = useCookies();

	const [color, setColor] = useState("blue");
	const [text, setText] = useState("Caricamento...");

	useEffect(() => {
		axios
			.get("https://vibes.mrfreex.net:8443/verify?token=" + query.get("token"), {
				headers: {
					token: cookies.token,
				},
			})
			.then((ans) => {
				setColor(ans.data.color);
				let set = "";

				switch (ans.data.color) {
					case "green":
						set = "Valido";
						break;
					case "yellow":
						set = "Evento diverso";
						break;
					case "orange":
						set = "Già scansionato";
						break;
					case "black":
						set = "Blacklist";
						break;
					default:
						set = "Non valido";
				}

				setText(set);
			})
			.catch(() => {
				setColor("purple");
			});
	}, []);

	return (
		<div>
			<div style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, backgroundColor: color }}>{text}</div>
		</div>
	);
};
