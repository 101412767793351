import { useState } from 'react';
import './App.css';

import {
  IAppContext,
  AppContext
} from './Context'

import { Login } from './Pages/Login';
import { Signup } from './Pages/Signup';
import { EditEvent, Events } from './Pages/Admin/Events'

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Panel } from './Pages/Panel';
import { AppInfo } from './Pages/AppInfo';
import { Scanned } from './Pages/Scanned';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Login />} />
      {/*<Route path="dashboard" element={} />*/ }
      <Route path='signup' element={<Signup />} />
      <Route path='app-info' element={<AppInfo />} />
      <Route path='verify' element={<Scanned />} />
      <Route path='panel'>
        <Route path='events'>
          <Route index element={<Events />} />
          <Route path='edit/:eventId' element={<EditEvent />} />
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  
  const contextState = useState<IAppContext>({});
  return (
    <AppContext.Provider value={contextState}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}

export default App;
