import axios from "axios";
import { useEffect, useRef, useContext, useState, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../Context";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import { Box, Button, Card, Divider, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { Add, Check, Delete, HdrPlus, Minimize, PlusOne, Remove } from "@mui/icons-material";

const STable = styled.table`
	padding: 10px 15px;
	border: solid 1px white;
	border-radius: 10px;
	text-align: center;

	td {
		padding: 10px 15px;
	}
`;

export type Event = {
	nome: string;
	slots: Record<number, [number, number, string]>;
	date: string;
	id: string;
};

const StackRow = styled.div<{ isTitle?: boolean }>`
	display: grid;
	grid-template-columns: 25% 25% 25% auto;
	width: 100%;

	${(props) => props.isTitle && "font-weight: 600;"}
`;

const CardWithLayout = styled(Card)`
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
	row-gap: 10px;
	background-color: #191919 !important;
`;

const CardT = styled.span`
	font-weight: 600;
`;

export const Events = () => {
	const [evs, setEvs] = useState<Event[]>([]);
	const [cookies] = useCookies();

	const nav = useNavigate();

	useEffect(() => {
		axios
			.post("https://vibes.mrfreex.net:8443/events/fetch", { token: cookies.token })
			.then((result) => {
				// console.log(result.data);

				// result.data.slots = JSON.parse(result.data.slots);

				for (const [key, value] of Object.entries(result.data as Event[])) {
					value.slots = JSON.parse(value.slots as unknown as string);
				}

				setEvs(result.data);
			})
			.catch(() => {});
	}, [setEvs]);

	// const slots = useMemo(() => {
	//     let slots: [number, number, string][] = [];
	//     evs.forEach((ev) => {
	//         slots.push([ev.slots10, ev.booked10 || 0, ""]);
	//         slots.push([ev.slots15, ev.booked15 || 0, ""]);
	//         slots.push([ev.slots20, ev.booked20 || 0, ""]);
	//     });
	//     return slots;
	// }, [evs]);

	// useEffect(() => {
	// 	console.log(evs);
	// }, [evs]);

	return (
		<Box margin={5}>
			<Button variant="contained" href="/panel/events/edit/new">
				Nuovo evento
			</Button>
			<br />
			<br />
			<Card style={{ padding: "20px" }} title="Eventi">
				<Stack spacing={2}>
					{/*

                <StackRow isTitle>
                    <div>Nome</div>
                    <div>Posti venduti / totali</div>
                    <div>ID</div>
                    <div>Azioni</div>
                </StackRow>
                
                */}

					{evs.length === 0
						? "Caricamento..."
						: evs.map((v, i) => {
								return (
									<CardWithLayout key={i}>
										<div>
											<CardT>Nome</CardT> {v.nome}
										</div>
										{/* <div>
											<CardT>Posti da 10</CardT> {v.booked10}/{v.slots10}
										</div>
										<div>
											<CardT>Posti da 15</CardT> {v.booked15}/{v.slots15}
										</div>
										<div>
											<CardT>Posti da 20</CardT> {v.booked20}/{v.slots20}
										</div> */}
										<Stack rowGap={0.5}>
											<CardT>Posti</CardT>
											{Object.entries(v.slots).map(([cost, [slots, booked, sheetCol]]) => {
												return (
													<span>
														{cost}€ : {booked}/{slots} [Colonna {sheetCol}]
													</span>
												);
											})}
										</Stack>
										<div>
											<CardT>ID</CardT> {v.id}
										</div>
										<div style={{ display: "flex", columnGap: "1ch" }}>
											<Button variant="contained" href={"/panel/events/edit/" + v.id}>
												Edit
											</Button>
											<Button
												variant="outlined"
												onClick={() => {
													axios
														.post("https://vibes.mrfreex.net:8443/events/delete", {
															token: cookies.token,
															id: v.id,
														})
														.then(() => {
															window.location.reload();
															//nav("/panel/events")
														})
														.catch((e) => {
															window.location.reload();
														});
												}}
											>
												Delete
											</Button>
										</div>
									</CardWithLayout>
								);
						  })}
				</Stack>
			</Card>

			{/*
        <STable>
            <thead>
                <td>
                    Nome    
                </td>   
                <td>
                    Posti venduti / totali
                </td> 
                <td>
                    ID
                </td> 
                <td>Azioni</td>
            </thead>    
            <tbody>
                {
                    evs.length === 0 ? "Caricamento..." : evs.map((v,i) => {
                        return <tr key={i}>
                            <td>{v.nome}</td>
                            <td>{v.booked15}/{v.slots15} {v.booked20}/{v.slots20}</td>
                            <td>{v.id}</td>
                            <td>
                                <Link to={"/panel/events/edit/" + v.id}>
                                    <button>Edit</button>
                                </Link>
                                <button onClick={() => {
                                    axios.post("https://vibes.mrfreex.net:8443/events/delete", {
                                        token : cookies.token,
                                        id : v.id
                                    }).then(() => {
                                        nav("/panel/events")
                                    }).catch(() => {})
                                }}>Delete</button>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </STable> 
        */}
		</Box>
	);
};

export const EditEvent = () => {
	let { eventId } = useParams();

	const [cookies] = useCookies();

	const nav = useNavigate();

	const [slots, setSlots] = useState<[number, number, string][]>([]);

	const refs = {
		id: useState(""),
		nome: useState(""),
		// slots10: useState(""),
		// slots15 : useState(""),
		// slots20 : useState(""),
		date: useState(""),
		where: useState(""),
		time: useState(""),
		google_sheet: useState(""),
	};

	useEffect(() => {
		axios
			.post("https://vibes.mrfreex.net:8443/events/get", {
				id: eventId,
				token: cookies.token,
			})
			.then((response) => {
				for (const [key, value] of Object.entries(refs)) {
					let p: string | string[] | undefined;
					if (key === "date") {
						p = (response.data[key] as string).split("/");
						p = p.reverse();
						p = p.join("-");
					}

					value[1](p || response.data[key]);
				}
				const newSlots: [number, number, string][] = [];
				for (const [key, value] of Object.entries(JSON.parse(response.data.slots) as Record<number, [number, number, string]>)) {
					// setSlots((slots) => [...slots, [parseInt(key), value[0], value[1]]])
					newSlots.push([parseInt(key), value[0], value[2]]);
				}
				setSlots(newSlots);
			})
			.catch(() => {});
	}, []);

	const Submit = () => {
		const values: { [k: string]: string | number | undefined } = {};
		for (const [key, value] of Object.entries(refs)) {
			if (key === "date") {
				values[key] = new Date(value[0]).toLocaleString("it-IT", { year: "numeric", month: "2-digit", day: "2-digit" });
				continue;
			}
			values[key] = value[0];
		}

		axios
			.post("https://vibes.mrfreex.net:8443/events/edit", {
				token: cookies.token,
				old_id: eventId,
				slots: slots,
				...values,
			})
			.then((ret) => {
				if (ret.status === 200) {
					nav("/panel/events");
				} else {
					alert("Errore, controlla i dati e riprova");
				}
			})
			.catch(() => {});
	};

	return (
		<Card
			style={{
				position: "absolute",
				left: "50%",
				top: "50%",
				transform: "translate(-50%,-50%)",
				borderRadius: "15px",
				minWidth: "30vw",
				maxWidth: "500px",
			}}
			title="Modifica Evento"
		>
			<Stack margin={5} maxWidth={500} spacing={2}>
				<TextField value={refs.id[0]} onChange={(e) => refs.id[1](e.target.value)} label="Id Evento" />
				<TextField value={refs.nome[0]} onChange={(e) => refs.nome[1](e.target.value)} label="Nome" />
				{/* <TextField value={refs.slots10[0]} onChange={(e) => refs.slots10[1](e.target.value)} label="Posti prevendite da 10" />
				<TextField value={refs.slots15[0]} onChange={(e) => refs.slots15[1](e.target.value)} label="Posti prevendite da 15" />
				<TextField value={refs.slots20[0]} onChange={(e) => refs.slots20[1](e.target.value)} label="Posti prevendite da 20" /> */}
				<Stack border="solid 1px #2a2a2a" borderRadius="10px" padding={2} rowGap={2}>
					<Stack direction="row" justifyContent="space-between">
						<span>Posti</span>
						<Button onClick={() => setSlots((slots) => [...slots, [0, 0, ""] as [number, number, string]])} variant="contained">
							<Add />
						</Button>
					</Stack>
					{/* <Stack paddingX={1} direction="row" justifyContent="space-between">
						<span>Costo</span>
						<span>Posti</span>
					</Stack> */}
					<Stack rowGap={1.5} divider={<Divider />} padding={1}>
						{slots.map(([cost, slots, sheetsCol], i) => {
							return (
								<Stack columnGap={1} key={i} direction="row" justifyContent="space-between">
									<TextField
										// value={cost}
										defaultValue={cost}
										onChange={(e) => {
											// slots[i][0] = parseInt(e.target.value);
											// setSlots(slots);
											setSlots((slots) => {
												slots[i][0] = parseInt(e.target.value);
												return slots;
											});
										}}
										label="Costo"
									/>
									<TextField
										// value={slots}
										defaultValue={slots}
										onChange={(e) => {
											setSlots((slots) => {
												slots[i][1] = parseInt(e.target.value);
												return slots;
											});
										}}
										label="Posti"
									/>
									<Tooltip title="La colonna del foglio sheets in cui incrementare i valori">
										<TextField
											// value={slots}
											defaultValue={sheetsCol}
											placeholder="N"
											onChange={(e) => {
												setSlots((slots) => {
													slots[i][2] = e.target.value.charAt(0);
													return slots;
												});
											}}
											label="Colonna sheets"
										/>
									</Tooltip>
									<Button
										onClick={() => {
											setSlots((slots) => {
												slots.splice(i, 1);
												return [...slots];
											});
										}}
										variant="text"
										sx={{ flex: 0 }}
									>
										<Delete />
									</Button>
								</Stack>
							);
						})}
					</Stack>
				</Stack>
				<TextField type="date" value={refs.date[0]} onChange={(e) => refs.date[1](e.target.value)} label="Data" />
				<TextField value={refs.time[0]} onChange={(e) => refs.time[1](e.target.value)} label="Ore (hh:mm)" />
				<TextField value={refs.where[0]} onChange={(e) => refs.where[1](e.target.value)} label="Indirizzo" />
				<TextField value={refs.google_sheet[0]} onChange={(e) => refs.google_sheet[1](e.target.value)} label="Foglio Sheets" />
				<br></br>
				<Button startIcon={<Check />} variant="contained" onClick={Submit}>
					Conferma
				</Button>
			</Stack>
		</Card>
	);
};
